import { animated } from '@react-spring/web'

import { useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useIsActive } from '/machinery/IsActive'

import { NudgingChevronCard } from '/features/buildingBlocks/NudgingChevron'
import { CardWithBackgroundImage } from '/features/regionArticles/cards/Card'
import { useReadMoreEnterAnimation } from '/features/regionArticles/useReadMoreEnterAnimation'

import mediaStyles from '/cssGlobal/media.css'
import styles from './CardHero.css'

export function CardHero({ item, region, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const { content } = item
  const { image, imageSecondary } = content.hero

  const isActive = useIsActive()
  const { showReadMore, readMoreAnimation } = useReadMoreEnterAnimation({ enabled: isActive, timeout: 4000 })

  const backgroundImage = isViewportMd && imageSecondary
    ? imageSecondary
    : image

  return (
    <CardWithBackgroundImage hasGradientBackground {...{ backgroundImage, layoutClassName }}>
      <div className={cx(styles.component, styles.coverCardLayout)}>
        <RegionAndIssue issue={item.issueTitle} layoutClassName={styles.regionAndIssueLayout} {...{ region }} />
        <ReadMore isActive={showReadMore} animation={readMoreAnimation} layoutClassName={styles.readMoreLayout} />
      </div>
    </CardWithBackgroundImage>
  )
}

function ReadMore({ isActive, animation, layoutClassName }) {
  const { __ } = useTranslate()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <animated.div style={animation} className={cx(styles.componentReadMore, layoutClassName)}>
      <NudgingChevronCard {...{ isActive }} />
      <p>{isViewportMd ? __`swipe-or-scroll-to-discover` : __`swipe-to-discover`}</p>
    </animated.div>
  )
}

function RegionAndIssue({ region, issue, layoutClassName }) {
  return (
    <div className={cx(styles.componentRegionAndIssue, layoutClassName)}>
      <h1 className={styles.region}>{region}</h1>
      <span className={styles.issue}>{issue}</span>
    </div>
  )
}
