import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { DeckFeed } from '/features/regionArticles/DeckFeed'
import { Loader } from '/features/pageOnly/Loader'

import mediaStyles from '/cssGlobal/media.css'

import styles from './DeckFeed.css'
import { SearchContextProvider } from '/machinery/SearchContext'

const queryClient = new QueryClient()
export default function DeckFeedUniversal({ items, regionData, issues }) {
  // We only render on the client because we need to position based on window size and don't want layout shift
  const mounted = useRenderOnMount()
  const preloadedMediaQueries = usePreloadedMediaQueries()
  const { region } = regionData

  if (!mounted || !preloadedMediaQueries) return (
    <div className={styles.componentUniversal}>
      <Loader layoutClassName={styles.loaderLayout} />
    </div>
  )

  return (
    <SearchContextProvider {...{ regionData }}>
      <QueryClientProvider client={queryClient} >
        <DeckFeed {...{ items, region, issues }} />
      </QueryClientProvider>
    </SearchContextProvider>
  )
}

function usePreloadedMediaQueries() {
  return [
    useMediaQuery(mediaStyles.viewportMd),
    useMediaQuery(mediaStyles.viewportLg),
  ].every(x => x !== null)
}
