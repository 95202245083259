// Note that this only works client side

export function useWindowHeight() {
  const [windowHeight, setWindowHeight] = React.useState(() => typeof window === 'undefined' ? 0 : window.innerHeight)
  React.useEffect(
    () => {
      handleResize()

      window.addEventListener('resize', handleResize, { passive: true })
      return () => { window.removeEventListener('resize', handleResize) }

      function handleResize() {
        setWindowHeight(window.innerHeight)
      }
    },
    []
  )
  return windowHeight
}
