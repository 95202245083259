import { useTranslate } from '/machinery/I18n'

import { CardWithGradientBackgroundCyan } from '/features/regionArticles/cards/Card'
import { CardUitgelezenContent } from '/features/regionArticles/cards/CardUitgelezenContent'

import styles from './CardUitgelezen.css'

export function CardUitgelezen({ item, issues, layoutClassName = undefined, animations = undefined }) {
  const { content } = item
  const { title } = content.hero

  return (
    <CardWithGradientBackgroundCyan contentContainerClassName={styles.component} {...{ layoutClassName }}>
      <CardUitgelezenContent layoutClassName={styles.contentLayout} {...{ title, issues, animations }} />
      <PoweredBy layoutClassName={styles.poweredByLayout} />
    </CardWithGradientBackgroundCyan>
  )
}

function PoweredBy({ layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentPoweredBy, layoutClassName)}>
      <div>{__`powered-by`}</div><div className={styles.rabobank}>Rabobank</div>
    </div>
  )
}
