import { useDrag } from '@use-gesture/react'
import { animated } from '@react-spring/web'

import { useItemUrl } from '/features/regionArticles/useItemUrl'
import { useCardAnimations } from '/features/regionArticles/cards/useCardAnimations'
import { Icon } from '/features/buildingBlocks/Icon'
import { HeadingGroup } from '/features/buildingBlocks/HeadingGroup'
import { CardWithBackgroundImage } from '/features/regionArticles/cards/Card'
import { MetaTagWrapper, MetaTag } from '/features/article/buildingBlocks/MetaTag'

import styles from './CardDefault.css'

import arrowRight from '/images/icons/arrow-right.raw.svg'

export function CardDefault({ item, layoutClassName = undefined }) {
  return <CardDefaultBase hasGradientBackground colorClassName={styles.white} {...{ item, layoutClassName }} />
}

export function CardDefaultBlack({ item, layoutClassName = undefined }) {
  return <CardDefaultBase colorClassName={styles.black} {...{ item, layoutClassName }} />
}

function CardDefaultBase({ item, colorClassName, hasGradientBackground = undefined, layoutClassName = undefined }) {
  const url = useItemUrl(item)

  const { content, metadata } = item
  const { hero } = content
  const { rubric, tags, readTime } = metadata

  const bindNavigateOnTap = useNavigateOnTap({ url })
  const animations = useCardAnimations()

  return (
    <CardWithBackgroundImage
      backgroundImage={hero.image}
      backgroundAnimation={animations.imageScale}
      contentContainerClassName={styles.component}
      gestureBindings={bindNavigateOnTap()}
      {...{ hasGradientBackground, layoutClassName }}
    >
      <Content
        title={hero.title}
        rubric={rubric.name}
        layoutClassName={styles.contentLayout}
        {...{ url, tags, readTime, colorClassName }}
      />
    </CardWithBackgroundImage>
  )
}

function Content({ title, rubric, url, tags, readTime, colorClassName, layoutClassName }) {
  const animations = useCardAnimations()

  return (
    <div data-x="feed-card" className={cx(styles.componentContent, colorClassName, layoutClassName)}>
      <HeadingGroup animation={animations.headingGroup} subtitle={rubric} {...{ title }} />

      <div className={styles.contentBottom}>
        <MetaTagWrapper animation={animations.metaData}>
          <MetaTag label={`${readTime} min`} />
          {tags.map((label, i) => <MetaTag key={i} {...{ label }} />)}
        </MetaTagWrapper>

        {url && (
          <NavigationButton
            animation={animations.navigationButton}
            layoutClassName={styles.navigationButtonLayout}
            {...{ url }}
          />
        )}
      </div>
    </div>
  )
}

function NavigationButton({ url, animation, layoutClassName = undefined }) {
  // TODO: Erik - this button has no effect because of the useNavigateOnTap in the parent. Buttons and things like that should prevent propagation
  return (
    <animated.a href={url} draggable='false' data-x='link-to-detail' className={cx(styles.componentNavigationButton, layoutClassName)} style={animation}>
      <Icon icon={arrowRight} />
    </animated.a>
  )
}

function useNavigateOnTap({ url }) {
  return useDrag(({ tap }) => { if (tap) window.location.href = url }, {})
}
