import { useItemUrl } from '/features/regionArticles/useItemUrl'
import { useCardAnimations } from '/features/regionArticles/cards/useCardAnimations'
import { CardWithGradientBackgroundOrange, CardPlusButtonLink } from '/features/regionArticles/cards/Card'
import { CardImage } from '/features/regionArticles/cards/CardImage'
import { HeadingGroupSnackables } from '/features/buildingBlocks/HeadingGroup'

import styles from './CardSingleSnackable.css'

export function CardSingleSnackable({ item, layoutClassName = undefined }) {
  return (
    <CardWithGradientBackgroundOrange contentContainerClassName={styles.component} {...{ layoutClassName }}>
      <CardSingleSnackableContent layoutClassName={styles.contentLayout} {...{ item }} />
    </CardWithGradientBackgroundOrange>
  )
}

function CardSingleSnackableContent({ item, layoutClassName }) {
  const href = useItemUrl(item)
  const animations = useCardAnimations()

  const { content, metadata } = item
  const { title, image } = content.hero
  const { rubric } = metadata

  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      {image && (
        <CardImage
          aspectRatio={4 / 5}
          animation={animations.imageScale}
          layoutClassName={styles.imageLayout}
          {...{ image }}
        />
      )}
      <div className={styles.headingContainer}>
        <HeadingGroupSnackables
          subtitle={rubric?.name}
          animation={animations.headingGroup}
          {...{ title }}
        />
      </div>
      <CardPlusButtonLink layoutClassName={styles.plusButtonLayout} {...{ href }} />
    </div>
  )
}
